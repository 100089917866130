import { descentBalanceManager } from '@/app/DescentBalanceManager'
import { endCalculationsManager } from '@/app/EndCalculationsManager'
import {
  disciplinePhasesManager,
  LandingPhaseManager,
  TakeOffPhaseManager
} from '@/app/phases'
import { player } from '@/app/Player'
import { windManager } from '@/app/WindManager'
import {
  actionButtonState,
  blurState,
  flightBalanceState,
  inputsState,
  tutorialState
} from '@/stores'
import {
  tutorialManager,
  TutorialSectionType,
  game,
  MobileDetector,
  gsap,
  requestManager,
  gameStats,
  modes
} from '@powerplay/core-minigames'
import {
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds,
  DisciplinePhases
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'
import {
  loadingCircleState,
  tutorialCoreState
} from '@powerplay/core-minigames-ui'
import { stateManager } from '@/app/StateManager'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  private activeEventType = TutorialEventType.awaitingEvent
  private firstInitDone = false

  /** General helper  */
  private helper = false

  public setDefaultObjective(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.speed as string,
        passed: false,
        failed: false,
        name: 'tutorialTask3-1'
      },
      {
        id: TutorialObjectiveIds.jump as string,
        passed: false,
        failed: false,
        name: 'tutorialTask3-2'
      },
      {
        id: TutorialObjectiveIds.jumpLength as string,
        passed: false,
        failed: false,
        name: 'tutorialTask3-3'
      },
      {
        id: TutorialObjectiveIds.landing as string,
        passed: false,
        failed: false,
        name: 'tutorialTask3-4'
      }
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    const tutorialSections = [
      {
        name: SectionNames.startSectionFirst,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          blurState().isActive = false

        }
      },
      {
        name: SectionNames.startSectionSecond,
        id: 1,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          inputsState().isVisible = false
          tutorialState().startbox = true

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          tutorialState().showButtonStart = true

        },
        sectionLogicFinish: () => {

          tutorialState().$patch({
            startbox: false,
            showButtonStart: false
          })
          inputsState().isVisible = true

        }
      },
      {
        name: SectionNames.startSectionFourth,
        id: 3,
        type: TutorialSectionType.gameButton
      },
      {
        name: SectionNames.startSectionFifth,
        id: 4,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          tutorialState().$patch({
            joystick: true,
            descend: true
          })

        },
        sectionLogicFinish: () => {

          tutorialState().$patch({
            joystick: false,
            descend: false
          })
          inputsState().isVisible = true

        }
      },
      {
        name: SectionNames.startSectionSixth,
        id: 5,
        type: TutorialSectionType.storyTimed
      },
      {
        name: SectionNames.startSectionSeventh,
        id: 6,
        type: TutorialSectionType.storyButton,
        sectionLogicIntro: () => {

          inputsState().isVisible = false
          tutorialState().takeoff = true

        },
        sectionLogicFinish: () => {

          game.resumeGame()
          actionButtonState().takeOffPressed = true
          const takeOffPhaseManager = disciplinePhasesManager
            .getPhaseManager(DisciplinePhases.takeoff) as TakeOffPhaseManager
          takeOffPhaseManager.calculateImpulse(true)
          tutorialState().takeoff = false
          inputsState().isVisible = true

        }
      },
      {
        name: SectionNames.startSectionEight,
        id: 7,
        type: TutorialSectionType.storyTimed
      },
      {
        name: SectionNames.startSectionNine,
        id: 8,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          inputsState().isVisible = false
          tutorialState().$patch({
            flight: true,
            balance: true
          })
          flightBalanceState().isActive = false

        },
        sectionLogicFinish: () => {

          tutorialState().$patch({
            flight: false,
            balance: false
          })
          flightBalanceState().isActive = true
          inputsState().isVisible = true

        }
      },
      {
        name: SectionNames.startSectionTen,
        id: 9,
        type: TutorialSectionType.storyTimed
      },
      {
        name: SectionNames.startSectionEleven,
        id: 10,
        type: TutorialSectionType.storyButton,
        sectionLogicIntro: () => {

          inputsState().isVisible = false
          tutorialState().$patch({
            landing: true,
            balanceHeight: true
          })

        },
        sectionLogicFinish: () => {

          game.resumeGame()
          const landingPhase = disciplinePhasesManager
            .getPhaseManager(DisciplinePhases.landing) as LandingPhaseManager
          landingPhase.inputsAction(3)
          tutorialState().$patch({
            landing: false,
            balanceHeight: false
          })

        }
      },
      {
        name: SectionNames.startSectionTwelve,
        id: 11,
        type: TutorialSectionType.storyTimed,
        sectionLogicIntro: () => {

          gsap.to({}, {
            duration: 1.5,
            onComplete: () => {

              tutorialManager.nextSection()

            }
          })

        }
      },
      {
        name: SectionNames.startSectionThirteen,
        id: 12,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          this.setDefaultObjective()

        }

      },
      {
        name: SectionNames.startSectionFourteen,
        id: 13,
        type: TutorialSectionType.storyTimed,
        sectionLogicIntro: () => {

          disciplinePhasesManager.reset()
          stateManager.resetPinia('tutorialCoreState')
          tutorialObjectives.reset()
          disciplinePhasesManager.startPhase(DisciplinePhases.start)
          player.reset()
          descentBalanceManager.reset()
          windManager.reset()
          endCalculationsManager.reset(true)
          inputsState().isVisible = true

        }
      },
      {
        name: SectionNames.startSectionFifteen,
        id: 14,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          if (tutorialObjectives.getAttempts() >= 3) {

            tutorialState().settings = true

          }

        }
      },
      {
        name: SectionNames.startSectionSixteen,
        id: 15,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          tutorialState().settings = false
          tutorialManager.setActualSectionId(13)

        }
      },
      {
        name: SectionNames.finish,
        id: 16,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          this.endGame()

        }
      }
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) return

    if (!this.firstInitDone) {

      this.firstInitDone = true
      tutorialManager.setActualSectionId(1)

    }

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()

    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType
    console.warn('trigger')

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()

    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    this.resetTypeWrite()
    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    tutorialCoreState().typeWriter = true

  }

  public finishAction(): void {

    console.warn('finish action')
    if (tutorialObjectives.checkIfAllObjectivesPassed()) {

      tutorialManager.setActualSectionId(16)

    } else {

      tutorialManager.nextSection()

    }
    // disciplinePhasesManager.resetAttempt()

  }

  /** redirect a ukoncenie hry */
  private endGame(): void {

    if (gameStats.wasExitedGame()) {

      requestManager.redirect()
      return

    }

    console.warn('TU')
    game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
    loadingCircleState().isActive = true

  }

}

export const tutorialFlow = new TutorialFlow()
